exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-contentful-blog-title-js": () => import("./../../../src/pages/blog/{ContentfulBlog.title}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-title-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-contentful-portfolio-title-js": () => import("./../../../src/pages/portfolio/{ContentfulPortfolio.title}.js" /* webpackChunkName: "component---src-pages-portfolio-contentful-portfolio-title-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-templates-category-template-for-blog-js": () => import("./../../../src/templates/category-template-for-blog.js" /* webpackChunkName: "component---src-templates-category-template-for-blog-js" */),
  "component---src-templates-tag-template-for-blog-js": () => import("./../../../src/templates/tag-template-for-blog.js" /* webpackChunkName: "component---src-templates-tag-template-for-blog-js" */),
  "component---src-templates-tag-template-for-portfolio-js": () => import("./../../../src/templates/tag-template-for-portfolio.js" /* webpackChunkName: "component---src-templates-tag-template-for-portfolio-js" */)
}

